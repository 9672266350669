import $ from "jquery";
import "jquery.scrollto";
// npm install jquery.scrollto

$(function() {
    $("a[href='#termin']").on("click", function() {
        const $scrolltarget = $(".bl-terminv").closest("section");
        $(window).scrollTo($scrolltarget, 1000);
    });
});

