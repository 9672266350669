import $ from "jquery";

function linearMap(inputValue, inputMin, inputMax, outputMin, outputMax)
{
	//clamp value
	if(inputValue <= inputMin) {
		return outputMin;
	} else if(inputValue >= inputMax) {
		return outputMax;
	}

	const diff = inputMax-inputMin;
	if(diff == 0) {
		//prevent division by zero
		if(inputValue <= inputMin) {
			return outputMin;
		} else {
			return outputMax;
		}
	}

	const factor = (inputValue-inputMin) / diff;
	const outputDiff = outputMax-outputMin;
	return outputMin + factor*outputDiff;
}

$(function() {
    const $quote = $(".bl-quote .ctrtext").first();
    const quote = $quote.get(0);
    quote.style.transition = "transform 0.1s ease-out";

    function updateQuote() {
        const rect = quote.getBoundingClientRect();
        const winh = window.innerHeight;

        const ymin = (window.innerWidth < 1024 ? winh*0.5 : winh*0.3);

        const inPct = linearMap(rect.top, ymin, winh, 1, 0);
        const inPctInverse = 1-inPct;
        //quote.style.opacity = inPct;
        quote.style.transform = "translateX("+inPctInverse*-200+"px)";
    }

    $(window).on("scroll", function() {
        requestAnimationFrame(updateQuote);
    });
    updateQuote();
    $(window).on("load", updateQuote);
});